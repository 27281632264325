
<template>
  <div class="teachers">
    <div class="title">
      <span class="s">教师评分</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="所属班级：">
          <el-select
            size="mini"
            v-model="formInline.class_id"
            placeholder="请选择"
            clearable
            @clear="handleReset"
          >
            <!-- <el-option label="全部" value=""></el-option> -->
            <el-option
              v-for="item in classList"
              :label="item.class_name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input
            clearable
            @clear="handleReset"
            v-model="formInline.username"
            size="mini"
            placeholder="登录名"
          ></el-input>
        </el-form-item>
        <el-form-item label="教师评分模块：">
          <el-select
            clearable
            @clear="handleReset"
            size="mini"
            v-model="formInline.module_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in teachermodulueTableData"
              :label="item.module_name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getTeacherList();
            "
            >查询</el-button
          >

          <el-button size="mini" type="success" @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          width="120"
          align="center"
          prop="member_name"
          label="用户名"
        >
        </el-table-column>
        <el-table-column align="center" prop="member_realname" label="真实姓名">
        </el-table-column>
        <el-table-column align="center" prop="class_name" label="班级名称">
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_default_score"
          label="默认分值"
        >
        </el-table-column>
        <el-table-column align="center" prop="teacher_score" label="教师总分">
        </el-table-column>

        <el-table-column
          align="center"
          prop="teacher_score_module_1"
          label="店铺注册"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_score_module_2"
          label="刊登产品"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_score_module_3"
          label="订单处理"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_score_module_4"
          label="客服邮件"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_score_module_5"
          label="营销推广"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="teacher_score_module_6"
          label="tests"
        >
        </el-table-column>
        <el-table-column
          width="180"
          align="center"
          prop="school_name"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="handleJump(scope.row)"
              >跳转</el-button
            >
            <el-button
              size="mini"
              plain
              type="success"
              @click="openTeacherScore(scope.row)"
              >评分</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :total="tableData.total"
        :page-size="page_size"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="教师评分"
      :visible.sync="dialogFormVisible"
      width="600px"
      top="3vh"
    >
      <el-form size="mini" label-width="160px">
        <div
          class="form_content"
          :key="index"
          v-for="(item, index) in teachermoduleform"
        >
          <el-form-item :label="`${item.module_name}(${max_score})`">
            <el-input
              type="number"
              :max="max_score"
              size="mini"
              v-model="item.score"
              autocomplete="off"
              @input="calcScore(index)"
            ></el-input>
          </el-form-item>
          <el-form-item :label="`${item.module_name}模块评语`">
            <el-input
              type="textarea"
              size="mini"
              v-model="item.compnents"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="教师评总分">
          <span>{{ teacher_total_score }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveTeacherScore()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getClassList,
  getTeacherPointList1,
  getTeacherScoreDetail,
  saveTeacherScoreS,
  getTeacherModuleList,
  exportTeacherScore1,
  examStudentLogin,
} from "@/api/admin.js";
import funDownload from "@/utils/exportExcel.js";
import { setBAuth, 
// setSAuth
 } from "@/utils/auth.js";
export default {
  data() {
    return {
      formInline: {
        username: "",
        class_id: "",
        module_id: "",
        teacher_status: "",
      },
      page: 1,
      page_size: 10,
      tableData: {
        list: [],
      },
      classList: [],
      dialogFormVisible: false,
      openTeacherScoreLoading: false,
      teachermoduleform: [],
      teacher_total_score: 0,
      current_teacher_student_info: [],
      max_score: 0,
      teachermodulueTableData: [],
      loading: true,
    };
  },
  created() {
    this.getTeacherList();
    this.getCampusList();
    this.getTeacherModuleList();
  },
  methods: {
    getCampusList() {
      getClassList().then((res) => {
        if (res.code == 1) {
          this.classList = res.data;
        }
      });
    },
    getTeacherList() {
      this.loading = true;
      // localStorage.USERTYPE = 4;
      getTeacherPointList1({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    pageChange(index) {
      this.page = index;
      this.getTeacherList();
    },
    // 重置
    handleReset() {
      this.page = 1;
      this.getTeacherList();
    },
    openTeacherScore(arr) {
      // 读取用户这个学生是否有评分了
      // localStorage.USERTYPE = 4;
      let vm = this;
      vm.openTeacherScoreLoading = true;
      getTeacherScoreDetail({
        school_id: arr["school_id"],
        class_id: arr["class_id"],
        school_student_string: arr["school_student_string"],
      }).then((res) => {
        if (res.code == 1) {
          vm.current_teacher_student_info["school_id"] = arr["school_id"];
          vm.current_teacher_student_info["class_id"] = arr["class_id"];
          vm.current_teacher_student_info["school_student_string"] =
            arr["school_student_string"];
          vm.teachermoduleform = res.data;
          vm.calcTotalScore();
          vm.max_score = arr.teacher_default_score;
          vm.dialogFormVisible = true;
        }
        vm.openTeacherScoreLoading = false;
      });
    },
    calcTotalScore: function () {
      let reviewed_count = 0;
      let total_score = 0;
      this.teachermoduleform.forEach((value) => {
  
        if (value.is_reviewed == 1) {
          reviewed_count++;
        }
        if (value.score != "") {
          total_score = parseFloat(total_score) + parseFloat(value.score);
        }
      });

      if (reviewed_count != 0) {
        this.teacher_total_score = (total_score / reviewed_count).toFixed(2);
      } else {
        this.teacher_total_score = 0;
      }
    },
    saveTeacherScore() {
      let vm = this;
      saveTeacherScoreS({
        teacher_data: vm.teachermoduleform,
        school_id: vm.current_teacher_student_info["school_id"],
        class_id: vm.current_teacher_student_info["class_id"],
        school_student_string:
          vm.current_teacher_student_info["school_student_string"],
      }).then((res) => {
        if (res.code == 1) {
          vm.$message.success("评分成功");
          vm.getTeacherPointList();
          vm.dialogFormVisible = false;
        }
      });
    },
    calcScore(index) {
      if (this.teachermoduleform[index].score != "") {
        this.teachermoduleform[index].is_reviewed = 1;
      } else {
        this.teachermoduleform[index].is_reviewed = 0;
      }
      if (this.teachermoduleform[index].score > this.max_score) {
        this.teachermoduleform[index].score = this.max_score;
      }
      this.calcTotalScore();
    },
    getTeacherModuleList() {
      let vm = this;
      getTeacherModuleList().then((res) => {
        if (res.code == 1) {
          vm.teachermodulueTableData = res.data;
        }
      });
    },
    handleExport() {
      // localStorage.USERTYPE = 4;
      exportTeacherScore1({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        let time = Date.now();
        time = this.formatTimeA(time, 1);
        funDownload(res, "教师评分-" + time + ".xlsx");
        this.$message.success("下载成功");
      });
    },
    handleJump(val) {
      let vm = this;
      // localStorage.USERTYPE = 4;
      examStudentLogin({
        member_id: val.member_id,
      }).then((res) => {
        if (res.code == 1) {
 
          vm.$confirm("确认跳转查看该考生详情进行评分吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              setBAuth(res.data.token);
              // setSAuth(res.data.token);
              window.open("home", "_blank");
            })
            .catch(() => {
              vm.$message("已取消评分操作!");
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.teachers {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
}
</style>